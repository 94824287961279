import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PageContainer } from "../Components/Layout/PageContainer";
import { SectionContainer } from "../Components/Layout/SectionContainer";
import { PageTitle } from "../Components/Layout/Layout";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextInput } from "../Components/Form/TextInput/TextInput";
import { Date } from "../Components/Form/Date/Date";
import { FileUpload } from "../Components/Form/FileUpload/FileUpload";
import { Checkbox } from "../Components/Form/Checkbox/Checkbox";
import { RadioButton } from "../Components/Form/RadioButton/RadioButton";
import { Button } from "../Components/Button/Button";
import { FaCheck } from "react-icons/fa6";
import { Select } from "../Components/Form/Select/Select";
import { Phone } from "../Components/Form/Phone/Phone";
import {
  getFormFields,
  getFormProducts,
  getHeaderFooter,
  getFormStores,
  postDraw,
  postSubmit,
  postUserDetails,
} from "../Utils/Services";
import { ErrorMessageBox } from "../Components/Form/ErrorMessageBox/ErrorMessageBox";
import Confetti from "react-dom-confetti";
import RoulettePro from "react-roulette-pro";
import "react-roulette-pro/dist/index.css";
import { Modal } from "../Components/Modal/Modal";
import { getLocalStorageItem, setLocalStorageItem } from "../Utils/Helpers";
import { validNricCodes } from "../Utils/Constants";
import { TermCheckbox } from "../Components/Form/Checkbox/TermCheckbox";

const config = {
  angle: "100",
  spread: 360,
  startVelocity: "50",
  elementCount: 70,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: "10px",
  height: "10px",
  perspective: "500px",
  colors: ["#f00", "#0f0", "#00f"],
};

export default function Participate() {
  const formikRef = React.useRef();
  const { slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);
  const [hasPageError, setHasPageError] = React.useState(false);
  const [pageErrorCode, setPageErrorCode] = React.useState(null);
  const [campaign, setCampaign] = React.useState({});
  const [header, setHeader] = React.useState([]);
  const [footer, setFooter] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [formFields, setFormFields] = React.useState([]);
  const [formProducts, setFormProducts] = React.useState([]);
  const [categoryList, setCategoryList] = React.useState([]);
  const [seriesList, setSeriesList] = React.useState([]);
  const [modelList, setModelList] = React.useState([]);
  const [rewardList, setRewardList] = React.useState([]);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [submissionId, setSubmissionId] = React.useState("");
  const [referenceNumber, setReferenceNumber] = React.useState("");
  const [canDraw, setCanDraw] = React.useState(false);
  const [isDraw, setIsDraw] = React.useState(false);
  const [prizeList, setPrizeList] = React.useState([]);
  const [result, setResult] = React.useState(0);
  const [isDrawing, setIsDrawing] = React.useState(false);
  const [showResult, setShowResult] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [storeList, setStoreList] = React.useState([]);
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [isAcerIdRequired, setIsAcerIdRequired] = React.useState(false);
  const [openLoginModal, setOpenLoginModal] = React.useState(false);
  const [openCampaignEndModal, setOpenCampaignEndModal] = React.useState(false);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [error, setError] = React.useState("");
  const [userDetails, setUserDetails] = React.useState({});
  const [disableSubmission, setDisableSubmission] = React.useState(false);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const headerFooterRes = await getHeaderFooter(slug);
    const res = await getFormFields(slug);
    const productsRes = await getFormProducts(slug);
    const storesRes = await getFormStores(slug);

    if (res.status === 200 && productsRes.status === 200) {
      setCampaign(headerFooterRes?.data?.campaign);
      setHeader(headerFooterRes?.data?.headers);
      setFooter(headerFooterRes?.data?.footers);
      setTitle(headerFooterRes?.data?.redemption?.title);
      setDescription(headerFooterRes?.data?.redemption?.description);
      setFormFields(res?.data);
      setCategoryList(productsRes?.data?.category);
      setStoreList(storesRes?.data);

      const hasAcerId = headerFooterRes?.data?.campaign?.has_acer_id;
      const isAllowSubmission =
        headerFooterRes?.data?.campaign?.is_allow_submission;
      setIsAcerIdRequired(hasAcerId);
      setDisableSubmission(!isAllowSubmission);

      if (headerFooterRes?.data?.campaign?.is_ended)
        setOpenCampaignEndModal(true);

      if (isAllowSubmission && hasAcerId) {
        const code = location?.search?.replace("?code=", "");

        if (code) {
          const detailsRes = await postUserDetails(code);
          if (detailsRes.status === 200) {
            await setLocalStorageItem("acerId", detailsRes.data);
            setUserDetails(detailsRes.data);
          } else {
            setError(detailsRes.data.message);
            setOpenErrorModal(true);
          }
        } else {
          const details = await getLocalStorageItem("acerId");
          if (details) setUserDetails(details);
          else setOpenLoginModal(true);
        }
      }
      setIsReady(true);
    } else {
      setHasPageError(true);
      setPageErrorCode(res.status);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (formFields.length == 0) return;
    const isShowProductDetails = formFields.find(
      (item) => item.section_slug === "default-product-details"
    )?.show_section;
    if (isShowProductDetails) {
      // setCategoryList()
      setSeriesList([]);
      setModelList([]);
      setRewardList([]);
      formikRef.current?.setFieldValue("category", "");
      formikRef.current?.setFieldValue("series", "");
      formikRef.current?.setFieldValue("model", "");
      formikRef.current?.setFieldValue("reward_ids", []);
    }
  }, [formFields]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // const addParamsToUrl = () => {
  //   const currentPath = location.pathname;
  //   const id = referenceNumber; // Example ID

  //   // Push the new URL with query params
  //   navigate(`${currentPath}?id=${id}`);
  // };

  // const handleStoreChange = (e) => {
  //   formikRef.current.setFieldValue("store", "");
  // };

  const handleCategoryChange = (e) => {
    formikRef.current.setFieldValue("series", "");
    formikRef.current.setFieldValue("model", "");
    formikRef.current.setFieldValue("reward_ids", []);

    setModelList([]);
    setRewardList([]);
    if (e.target.value === "") {
      setSeriesList([]);
      return;
    }
    const selectedCategoryObj = categoryList.filter(
      (item) => item.value === e.target.value
    );
    setSeriesList(selectedCategoryObj[0].series);
  };

  const handleSeriesChange = (e) => {
    formikRef.current.setFieldValue("model", "");
    formikRef.current.setFieldValue("reward_ids", []);
    setRewardList([]);
    if (e.target.value === "") {
      setModelList([]);
      return;
    }
    const selectedSeriesObj = seriesList.filter(
      (item) => item.value === e.target.value
    );
    setModelList(selectedSeriesObj[0].model);
  };

  const handleModelChange = (e) => {
    if (e.target.value === "") {
      formikRef.current.setFieldValue("reward_ids", []);
      setRewardList([]);
      return;
    }
    const selectedModelObj = modelList.filter(
      (item) => item.value == e.target.value
    );
    const rewards = selectedModelObj[0].rewards;
    setRewardList(rewards);

    let ids = [];
    rewards.map((reward) => {
      if (reward.is_selectable) ids.push(reward.value);
    });
    formikRef.current.setFieldValue("reward_ids", ids);
  };

  const handleRewardChange = (name, optionValue) => {
    const currentValues = formikRef.current.values[name] || [];
    const newValue = currentValues.includes(optionValue)
      ? currentValues.filter((value) => value !== optionValue)
      : [...currentValues, optionValue];
    formikRef.current.setFieldValue(name, newValue);
  };

  const getFullYearFromNRIC = (nricDate) => {
    const currentYearLastTwoDigits = new window.Date()
      .getFullYear()
      .toString()
      .slice(-2);
    const yearPrefix = parseInt(nricDate.slice(0, 2), 10);
    if (yearPrefix <= currentYearLastTwoDigits) {
      return 2000 + yearPrefix;
    } else {
      return 1900 + yearPrefix;
    }
  };

  // Utility function to check if the date is at least 18 years ago
  const isAtLeast18YearsOld = (dob) => {
    const today = new window.Date();
    const [day, month, year] = dob.split("/").map(Number);
    const birthDate = new window.Date(year, month - 1, day);
    const eighteenYearsAgo = new window.Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    return birthDate <= eighteenYearsAgo;
  };

  function isValidStateCode(val) {
    const match = val.match(/^\d{6}-(\d{2})-\d{4}$/);
    if (match) {
      const middleValue = match[1];
      // Check if the middle value is in the list of valid numbers
      return validNricCodes.includes(middleValue);
    }

    // Return false if format is invalid or middle value does not match
    return false;
  }

  // Custom NRIC validation function
  const validateNRIC = (nric) => {
    const nricPattern = /^\d{6}-\d{2}-\d{4}$/;
    if (!nricPattern.test(nric)) {
      return "Invalid NRIC format";
    }

    const dob = nric.slice(0, 6); // e.g., '950201'
    const year = getFullYearFromNRIC(dob.slice(0, 2)); // Convert year with century
    const month = dob.slice(2, 4);
    const day = dob.slice(4, 6);

    // Format date as DD/MM/YYYY
    const formattedDOB = `${day}/${month}/${year}`;

    if (!isAtLeast18YearsOld(formattedDOB)) {
      return "You must be at least 18 years old";
    }

    if (!isValidStateCode(nric)) {
      return "State code is invalid";
    }

    return null; // Valid NRIC
  };

  const generateYupSchema = (fields) => {
    let yupObject = {};
    fields.forEach((field) => {
      let fieldValidator;
      if (
        field.input_type === "checkbox" &&
        field.field_name === "reward_ids"
      ) {
        fieldValidator = Yup.array()
          .of(Yup.string()) // Ensure each item in the array is a string
          .min(1, "At least one option must be selected") // At least one checkbox must be selected
          .required("This field is required");
      } else {
        fieldValidator = Yup.string();
        if (field.field_required === "yes") {
          if (field.input_type === "checkbox") {
            if (
              field.field_name === "promotion" ||
              field.field_name === "terms"
            ) {
              fieldValidator = Yup.boolean().oneOf(
                [true],
                `This field is required` //`${field.field_label} is required`
              );
            } else {
              fieldValidator = Yup.array()
                .of(Yup.string()) // Ensure that the items in the array are strings
                .min(1, "At least one option must be selected") // At least one checkbox must be selected
                .required("This field is required"); // This field is required
            }
          } else {
            fieldValidator = fieldValidator.required(`This field is required`);
          }
        }
        if (field.input_type === "email") {
          fieldValidator = fieldValidator.email(
            "Please enter a valid email address."
          );
        }
        if (field.field_name === "ic") {
          fieldValidator = fieldValidator.test(
            "validate-nric",
            ({ value }) => {
              if (!value) return "This field is required";
              return validateNRIC(value);
            },
            (value) => {
              if (!value) return false;
              return validateNRIC(value) === null;
            }
          );
        }
      }
      yupObject[field.field_name] = fieldValidator;
    });
    return Yup.object().shape(yupObject);
  };

  const validate = async (values) => {
    try {
      const validationSchema = generateYupSchema(
        formFields.reduce((acc, section) => acc.concat(section.fields), [])
      );
      await validationSchema.validate(values, { abortEarly: false });
      return {}; // No validation errors
    } catch (errors) {
      const validationErrors = {};
      errors.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });

      scrollToTop();

      return validationErrors;
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setIsLoading(true);
      setSubmitting(true);
      setErrorMsg("");
      setHasError(false);

      // Construct formData
      const formData = new FormData();

      Object.keys(values).forEach((fieldName) => {
        const value = values[fieldName];
        // Handle files separately
        if (value instanceof FileList) {
          for (let i = 0; i < value.length; i++) {
            formData.append(fieldName, value[i]);
          }
        } else if (Array.isArray(value)) {
          formData.append(fieldName, JSON.stringify(value)); // Stringify the array
        } else {
          formData.append(fieldName, value);
        }
      });
      formData.append("uuid", userDetails?.uuid ?? "");

      const res = await postSubmit(slug, formData);

      if (res.status === 200) {
        setSubmissionId(res?.data?.data?.submission_id);
        setReferenceNumber(res?.data?.data?.reference_number);
        const showRedeemGift = res?.data?.data?.show_lucky_draw;
        setCanDraw(showRedeemGift);

        if (showRedeemGift) {
          const prizes = res?.data?.data?.lucky_draws;
          const reproducedPrizeList = [
            ...prizes,
            ...prizes,
            ...prizes,
            ...prizes,
            ...prizes,
            ...prizes,
            ...prizes,
            ...prizes,
            ...prizes,
            ...prizes,
          ];

          setPrizeList(
            reproducedPrizeList.map((prize, index) => ({
              text: prize,
              id: index,
            }))
          );
        }

        setIsSuccess(true);
      } else if (res.status === 422) {
        checkField(res.data);
      } else {
        setErrorMsg("Error");
        setHasError(true);
        scrollToTop();
      }
    } catch (error) {
      console.error("Form submission error:", error);
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  const checkField = (errors) => {
    Object.keys(errors).forEach(function (key) {
      formikRef.current.setFieldError(key, errors[key][0]);
    });
    scrollToTop();
  };

  const findLastMatchingItemFromEnd = (criteria) => {
    // Iterate from the end of the array
    for (let i = prizeList?.length - 1; i >= 0; i--) {
      if (prizeList[i]?.text === criteria) {
        return prizeList[i]; // Return the first matching item found from the end
      }
    }

    return null; // Return null if no matching item is found
  };

  const handleStartDraw = async () => {
    try {
      const res = await postDraw(slug, {
        submission_id: submissionId,
      });
      if (res.status === 200) {
        const matched = findLastMatchingItemFromEnd(res.data?.lucky_draw?.name);
        setResult(matched.id);
        setIsDrawing(true);
      } else {
        setErrorMsg(res?.data?.error || "Error");
        setHasError(true);
      }
    } catch (error) {
      console.error("Draw error:", error);
    } finally {
    }
  };

  const renderField = (field) => {
    switch (field.input_type) {
      case "short-text":
        if (field.field_name === "mobile_number") {
          return (
            <Phone
              key={field.id}
              className="w-1/2  p-2"
              label={field.field_label}
              name={field.field_name}
              placeholder={field.field_description}
              required={field.field_required === "yes"}
            />
          );
        } else if (field.field_name === "ic") {
          return (
            <TextInput
              key={field.id}
              className="w-1/2 p-2"
              label={field.field_label}
              name={field.field_name}
              type="text"
              placeholder={field.field_description || "XXXXXX-XX-XXXX"}
              required={field.field_required === "yes"}
              // isOnlyAlphanumeric={true}
              isNric={true}
            />
          );
        }
        return (
          <TextInput
            key={field.id}
            className="w-1/2 p-2"
            label={field.field_label}
            name={field.field_name}
            type="text"
            placeholder={field.field_description}
            required={field.field_required === "yes"}
          />
        );
      case "long-text":
        return (
          <TextInput
            key={field.id}
            className="w-full p-2"
            label={field.field_label}
            name={field.field_name}
            type="text"
            placeholder={field.field_description}
            required={field.field_required === "yes"}
          />
        );
      case "numbers":
        return (
          <TextInput
            key={field.id}
            className="w-1/2 p-2"
            label={field.field_label}
            name={field.field_name}
            type="number"
            placeholder={field.field_description}
            required={field.field_required === "yes"}
          />
        );
      case "email":
        return (
          <TextInput
            key={field.id}
            className="w-full p-2"
            label={field.field_label}
            name={field.field_name}
            type="email"
            placeholder={field.field_description}
            required={field.field_required === "yes"}
            disabled={isAcerIdRequired}
          />
        );
      case "date":
        if (field.field_name === "invoice_date") {
          return (
            <Date
              key={field.id}
              className="w-1/2 p-2"
              label={field.field_label}
              name={field.field_name}
              placeholder={field.field_description}
              required={field.field_required === "yes"}
              minDate={campaign?.start_date}
              maxDate={campaign?.end_date}
            />
            // <Field
            //   name={field.field_name}
            //   render={({ field, form }) => (
            //     <Datepicker
            //       className="w-1/2"
            //       label={field.field_label}
            //       name={field.field_name}
            //       name={field.field_name}
            //       placeholder={field.field_description}
            //       required={field.field_required === 'yes'}
            //       onChange={(date) => form.setFieldValue(field.name, date)}
            //       onBlur={() => form.setFieldTouched(field.name, true)}
            //       value={field.value}
            //     />
            //   )}
            // />
          );
        }
        return (
          <Date
            key={field.id}
            className="w-1/2 p-2"
            label={field.field_label}
            name={field.field_name}
            placeholder={field.field_description}
            required={field.field_required === "yes"}
          />
        );
      case "dropdown":
        if (field.field_name === "category") {
          return (
            <Select
              key={field.id}
              className="w-1/2 p-2"
              label={field.field_label}
              name={field.field_name}
              options={categoryList}
              onChange={(e) => {
                // formikRef.current.setFieldValue("category", e.target.value);
                handleCategoryChange(e);
              }}
              placeholder={field.field_description}
              required={field.field_required === "yes"}
            />
          );
        }
        if (field.field_name === "series") {
          return (
            <Select
              key={field.id}
              className="w-1/2 p-2"
              label={field.field_label}
              name={field.field_name}
              options={seriesList}
              onChange={(e) => {
                // formikRef.current.setFieldValue("series", e.target.value);
                handleSeriesChange(e);
              }}
              placeholder={field.field_description}
              required={field.field_required === "yes"}
            />
          );
        }
        if (field.field_name === "model") {
          return (
            <Select
              key={field.id}
              className="w-1/2 p-2"
              label={field.field_label}
              name={field.field_name}
              options={modelList}
              onChange={(e) => {
                handleModelChange(e);
              }}
              placeholder={field.field_description}
              required={field.field_required === "yes"}
            />
          );
        }
        if (field.field_name === "store_id") {
          return (
            <Select
              key={field.id}
              className="w-full p-2"
              label={field.field_label}
              name={field.field_name}
              options={storeList}
              // onChange={handleStoreChange}
              placeholder={field.field_description}
              required={field.field_required === "yes"}
            />
          );
        }
        return (
          <Select
            key={field.id}
            className="w-1/2 p-2"
            label={field.field_label}
            name={field.field_name}
            options={field.field_options}
            placeholder={field.field_description}
            required={field.field_required === "yes"}
          />
        );
      case "file-upload":
        return (
          <FileUpload
            key={field.id}
            className="w-full sm:w-4/5 p-2"
            label={field.field_label}
            name={field.field_name}
            placeholder={field.field_description}
            required={field.field_required === "yes"}
          />
        );
      case "boolean":
        return <div />;
      case "checkbox":
        if (field.field_name === "terms") {
          return (
            <TermCheckbox
              key={field.id}
              className="w-full"
              label=""
              options={[
                {
                  label:
                    "I hereby agree to the terms & conditions of this program and Acer's privacy policy. For more information of how Acer collects and handles my personal information.",
                  value: "",
                },
              ]}
              name={field.field_name}
              placeholder={field.field_description}
              required={field.field_required === "yes"}
            />
          );
        } else if (field.field_name === "promotion") {
          return (
            <TermCheckbox
              key={field.id}
              className="w-full"
              label=""
              name={field.field_name}
              options={[
                {
                  label:
                    "I hereby agree to receive promotional content from Acer products and that Acer can use my personal information for marketing purposes.",
                  value: "",
                },
              ]}
              placeholder={field.field_description}
              required={field.field_required === "yes"}
            />
          );
        } else if (field.field_name === "reward_ids") {
          if (rewardList?.length > 0)
            return (
              <Checkbox
                key={field.id}
                className="w-full"
                label={field.field_label}
                name={field.field_name}
                options={rewardList}
                placeholder={field.field_description}
                required={field.field_required === "yes"}
                handleChange={handleRewardChange}
              />
            );
          return null;
        }
        return (
          <Checkbox
            key={field.id}
            className="w-full p-2"
            label={field.field_label}
            name={field.field_name}
            options={field.field_options}
            placeholder={field.field_description}
            required={field.field_required === "yes"}
          />
        );
      case "radio-button":
        // if (field.field_name === "reward_ids") {
        //   if (rewardList?.length > 0)
        //     return (
        //       <RadioButton
        //         key={field.id}
        //         className="w-full p-2"
        //         label={field.field_label}
        //         name={field.field_name}
        //         options={rewardList}
        //         // onChange={(e) => {
        //         //   formikRef.current.setFieldValue("reward_ids", e.target.value);
        //         // }}
        //         placeholder={field.field_description}
        //         required={field.field_required === "yes"}
        //       />
        //     );
        //   return null;
        // }
        return (
          <RadioButton
            key={field.id}
            className="w-full"
            label={field.field_label}
            name={field.field_name}
            options={field.field_options}
            placeholder={field.field_description}
            required={field.field_required === "yes"}
          />
        );
      default:
        return null;
    }
  };

  return (
    <PageContainer
      isLoading={isLoading}
      isReady={isReady}
      slug={slug}
      campaign={campaign}
      header={header}
      footer={footer}
      hasPageError={hasPageError}
      pageErrorCode={pageErrorCode}
    >
      <SectionContainer
        sectionContainerClassName="bg-gray-50"
        containerClassName="!max-w-screen-md py-8 sm:py-12"
      >
        <PageTitle
          title={title}
          subtitle={`${submissionId ? "" : description}`}
        />
        {hasError && <ErrorMessageBox message={errorMsg} />}
        {isSuccess ? (
          isDraw ? (
            <div className="flex flex-col gap-8 pt-2.5">
              <div>
                <div className="h-[234px] border-[12px] border-double border-primary-600 rounded-3xl">
                  <RoulettePro
                    type="vertical"
                    start={isDrawing}
                    prizes={prizeList}
                    prizeIndex={result}
                    spinningTime={3}
                    options={{ stopInCenter: true, withoutAnimation: true }}
                    defaultDesignOptions={{
                      hideCenterDelimiter: true,
                      prizesWithText: true,
                    }}
                    prizeItemRenderFunction={({ text }) => (
                      <div
                        className="roulette-pro-regular-prize-item"
                        style={{ height: 234 }}
                      >
                        <div
                          className={"roulette-pro-regular-prize-item-wrapper"}
                        >
                          <p className="roulette-pro-regular-prize-item-text">
                            {text}
                          </p>
                        </div>
                      </div>
                    )}
                    onPrizeDefined={() => setShowResult(true)}
                  />
                </div>
                <div className="relative flex justify-center items-center z-30">
                  <div className="absolute -top-28">
                    <Confetti active={showResult} config={config} />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-4">
                {showResult ? (
                  <Button
                    primary
                    label="Done"
                    onClick={() => window.location.reload()}
                  />
                ) : (
                  <Button
                    primary
                    label="Start"
                    onClick={handleStartDraw}
                    disabled={isDrawing}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-8 pt-2.5">
              <div
                className={`${styles.formContainer} flex-col justify-center items-center gap-6 pt-6 pb-8 px-8`}
              >
                <div className="w-24 h-24 rounded-full bg-green-900 flex justify-center items-center">
                  <FaCheck className="w-14 h-14 text-green-400" />
                </div>
                <div className="space-y-1">
                  <p className="text-lg font-extrabold text-center">
                    Reference Number: {referenceNumber}
                  </p>
                  <p className="text-lg font-semibold text-center">
                    We&#39;ve got your redemption request!{" "}
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-600 text-center">
                    Thank you for taking part in our campaign. We will now
                    verify your redemption request. This process will take 2-5
                    business days and your patience is highly appreciated.
                    <br />
                    <br />
                    {formikRef?.current?.values?.email ||
                    formikRef?.current?.values?.mobile_number ? (
                      <>
                        After processing, updates will be sent to:
                        <br />
                        <span className="font-bold">
                          {formikRef?.current?.values?.email}
                        </span>
                        <br />
                        <span className="font-bold">
                          {formikRef?.current?.values?.mobile_number}
                        </span>
                      </>
                    ) : null}
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                {canDraw && (
                  <Button
                    primary
                    label="Redeem Gift"
                    onClick={() => setIsDraw(true)}
                  />
                )}
                <Button
                  primary={!canDraw}
                  secondary={canDraw}
                  label="Done"
                  onClick={() => window.location.reload()}
                />
              </div>
            </div>
          )
        ) : (
          <Formik
            innerRef={formikRef}
            initialValues={{
              ...formFields?.reduce((acc, section) => {
                section.fields.forEach((field) => {
                  if (isAcerIdRequired) {
                    if (field.field_name === "email") {
                      acc[field.field_name] = userDetails.email;
                    } else if (field.field_name === "full_name") {
                      acc[field.field_name] = userDetails.full_name;
                    } else if (field.field_name === "mobile_number") {
                      acc[field.field_name] = userDetails.mobile_number;
                    } else {
                      acc[field.field_name] =
                        field.input_type === "checkbox" ? false : "";
                    }
                    // email: "siewyen@rhingle.com"
                    // full_name: "Siew Yen"
                    // mobile_number: null
                    // mobile_number_number: null
                    // mobile_number_prefix: null
                    // uuid: "27466e0f6b3a11efa38606350104c453"
                  } else {
                    acc[field.field_name] =
                      field.input_type === "checkbox" ? false : "";
                  }
                });
                return acc;
              }, {}),
            }}
            validateOnBlur={false}
            validateOnChange={false}
            // validationSchema={generateYupSchema(
            //   formFields.reduce(
            //     (acc, section) => acc.concat(section.fields),
            //     []
            //   )
            // )}
            validate={validate}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, isSubmitting, isValidating }) => (
              <Form
                onSubmit={handleSubmit}
                className="flex flex-col gap-8 pt-2.5"
              >
                {formFields.map((section, index) => {
                  if (section.show_section) {
                    if (section.section_slug !== "default-terms") {
                      return (
                        <div
                          key={index}
                          className={`${styles.formContainer} flex-wrap p-4 pb-8`}
                        >
                          <p className={styles.formTitle}>
                            {section.section_name}
                          </p>
                          {section.fields?.length > 0 &&
                            section.fields.map((field) => renderField(field))}
                        </div>
                      );
                    }
                  }
                  return null;
                })}

                {formFields.map((section, index) => {
                  if (section.show_section) {
                    if (section.section_slug === "default-terms") {
                      return (
                        <div key={index} className="space-y-4">
                          {section.fields?.length > 0 &&
                            section.fields.map((field) => renderField(field))}
                        </div>
                      );
                    }
                  }
                  return null;
                })}
                {disableSubmission ? (
                  <Button primary label="Submit" disabled={true} />
                ) : (
                  <Button
                    primary
                    label="Submit"
                    type="submit"
                    disabled={isSubmitting || isValidating}
                  />
                )}
              </Form>
            )}
          </Formik>
        )}
      </SectionContainer>

      {/* Acer ID */}
      <Modal
        open={openLoginModal}
        setOpen={setOpenLoginModal}
        showCloseButton={false}
      >
        Please sign in with your Acer ID to continue.
        <a
          className="py-3 px-5 rounded-lg text-body font-medium bg-primary-700 text-white disabled:bg-gray-200 disabled:text-white"
          href={`https://${process.env.REACT_APP_ACER_ENDPOINT}/my-en/account/sign-in?redirecturl=${process.env.REACT_APP_DOMAIN}${location.pathname}&clientId=${process.env.REACT_APP_ACER_CLIENT_ID}`}
        >
          Sign in
        </a>
      </Modal>

      <Modal
        open={openErrorModal}
        setOpen={setOpenErrorModal}
        showCloseButton={true}
      >
        <p className="">Error</p>
        {error}
      </Modal>

      <Modal
        open={openCampaignEndModal}
        setOpen={setOpenCampaignEndModal}
        showCloseButton={true}
      >
        Oops, the campaign has ended.
      </Modal>
    </PageContainer>
  );
}

const styles = {
  formContainer: `flex border border-gray-200 rounded-lg bg-white shadow-sm`,
  formTitle: `w-full text-gray-900 text-xl font-semibold p-2`,
};
